<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('yoksis_parameter')" :isFilter="false">
                </Header>
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile :title="$t('yoksis_parameter')" :isFilter="false">
                </HeaderMobile>
            </template>
            <datatable :isLoading.sync="datatable.isLoading"
                       :columns="datatable.columns"
                       :rows="datatable.rows"
                       :total="datatable.total"
                       :queryParams="datatable.queryParams"
                       :lineNumbers="false"
                       @on-page-change="onPageChange"
                       @on-sort-change="onSortChange"
                       @on-per-page-change="onPerPageChange"
                       v-show="datatable.showTable"
            >
            </datatable>
            <CommonModal ref="showModal" size="xl" :onHideOnlyX="true" @bHideModalHeaderClose="formClear()" v-if="checkPermission('yoksisservice_yoksisparameter')">
                <template v-slot:CommonModalTitle>
                    {{ $t('show').toUpper() }}
                </template>
                <template v-slot:CommonModalContent>
                    <show-form :data="showData"
                               v-if="formProcess=='show'"
                    />
                </template>
            </CommonModal>
        </app-layout>
    </div>
</template>
<script>
import AppLayout from "@/layouts/AppLayout"
import Header from "@/layouts/AppLayout/Header"
import HeaderMobile from "@/layouts/AppLayout/HeaderMobile"
import Datatable from "@/components/datatable/Datatable";
import qs from 'qs'
import CommonModal from "@/components/elements/CommonModal";
import {ValidationObserver, ValidationProvider} from "vee-validate";
import YoksisParameterService from "@/services/YoksisParameterService";
import ShowForm from "@/modules/yoksisParameter/pages/ShowForm.vue";
export default {
    components: {
        ShowForm,
        AppLayout,
        Header,
        Datatable,
        CommonModal,
        HeaderMobile,
        ValidationProvider,
        ValidationObserver
    },
    metaInfo() {
        return {
            title: this.$t('yoksis_parameter')
        }
    },
    data() {
        return {
            formId: 0,
            showData: null,
            form: {},
            datatable: {
                isLoading: false,
                columns: [
                    {
                        label: '',
                        field: 'buttons',
                        html: true,
                        sortable: false,
                        tdClass: 'p-0 text-center w-40 align-middle',
                        buttons: [
                            {
                                text: this.$t('show'),
                                class: 'ri-search-eye-line',
                                permission: 'yoksisservice_yoksisparameter',
                                callback: (row) => {
                                    this.showForm(row);
                                }
                            },
                            {
                                text: this.$t("parameter_items"),
                                class: "ri-list-check ",
                                permission: 'yoksisservice_yoksisparameter',
                                callback: (row) => {
                                    this.formId = row.kod;
                                    this.openParameter(row.kod);
                                },
                            },
                        ]
                    },
                    {
                        label: this.$t('code'),
                        field: 'kod',
                        sortable: true,
                    },
                    {
                        label: this.$t('name'),
                        field: 'ad',
                        sortable: true,
                    },
                ],
                rows: [],
                total: 0,
                filterStatus: false,
                showTable: true,
                queryParams: {
                    filter: {
                    },
                    sort: 'id',
                    page: 1,
                    limit: -1
                }
            }
        }
    },
    created() {
        this.getRows()
    },
    methods: {
        openParameter() {
            if (this.formId > 0) {
                this.$router.push("/yoksis/parameter/items/" + this.formId);
            }
        },
        onPageChange(params) {
            this.datatable.queryParams.page = params.currentPage;
            this.getRows();
        },
        onPerPageChange(params) {
            this.datatable.queryParams.limit = params.currentPerPage;
            this.datatable.queryParams.page = 1;
            this.getRows();
        },
        onSortChange(params) {
            const sortType = params[0].type == 'desc' ? '-' : '';
            this.datatable.queryParams.sort = sortType + params[0].field
            this.getRows();
        },
        getRows() {
            this.datatable.showTable = true;
            this.datatable.isLoading = true;

            const config = {
                params: {
                    ...this.datatable.queryParams
                },
                paramsSerializer: (params) => qs.stringify(params, {encode: false})
            };

            return YoksisParameterService.getAll(config)
                .then((response) => {
                    this.datatable.rows = response.data.data
                    this.datatable.total = response.data.pagination.total
                })
                .finally(() => {
                    this.datatable.isLoading = false;
                });
        },
        showForm(data) {
            this.formId = data.kod
            this.showData = data
            this.formProcess = 'show'
            this.$refs.showModal.$refs.commonModal.show()
        },
    }
};
</script>

