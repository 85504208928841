<template>
    <div>
        <b-row>
            <b-col cols="12">
                <b-form-group :label="$t('code')">
                    <b-input-group>
                        <div class="label-as-input">{{ isNotNullValue(data.kod) }}</div>
                    </b-input-group>
                </b-form-group>
            </b-col>
            <b-col cols="12">
                <b-form-group :label="$t('name')">
                    <b-input-group>
                        <div class="label-as-input">{{ isNotNullValue(data.ad) }}</div>
                    </b-input-group>
                </b-form-group>
            </b-col>
        </b-row>
    </div>
</template>

<script>
export default {
    props: {
        data: {
            type: Object,
            required: true
        }
    },
}
</script>
